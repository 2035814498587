import React from "react"
import "../../styles/PrivacyPolicy.css"

export default function PrivacyPolicyContent() {
    return <div className="container">
        <p className="s1" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'center' }}>Privacy Policy</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>Effective date: 04/11/16</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'justify' }}>
            We at Henry Labs know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy.
            <b> By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the
                following ways.</b>
        </p>
        <p style={{ paddingTop: '10pt', textIndent: '0pt', textAlign: 'left' }}>
            Remember that your use of Henry Labs’ Services is at all times subject to the Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of
            Use.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s1" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'left' }}>What does this Privacy Policy cover<span className="s2">?</span></p>
        <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'justify' }}>
            This Privacy Policy covers our treatment of personally identifiable information ("Personal Information") that we gather when you are accessing or using our Services, but not to the practices of companies we don’t own or
            control, or people that we don’t manage. We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to
            personalize, provide, and improve our services, to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you
            use the Services. In certain cases, we may also share some Personal Information with third parties, but only as described below.
        </p>
        <p style={{ paddingTop: '10pt', textIndent: '0pt', textAlign: 'justify' }}>
            <p>
                As noted in the Terms of Use, we do not knowingly collect or solicit personal information from anyone under the age of 16. If you are under 16, please do not attempt to register for the Services or send any personal information
                about yourself to us. If we learn that we have collected personal information from a child under age 16, we will delete that information as quickly as possible. If you believe that a child under 16 may have provided us personal
                information, please contact us at <a href="mailto:support@clerkie.io" className="s3" target="_blank"> <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span></a>.</p>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s1" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>Will Henry Labs ever change this Privacy Policy<span className="s2">?</span></p>
        <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'justify' }}>
            We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on the <a href="https://clerkie.io/" className="a" target="_blank">https://clerkie.io</a>, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us
            with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been
            posted, that means you agree to all of the changes. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is collected.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s1" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'justify' }}>Data Storage</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
            Henry Labs uses third-party vendors and hosting partners, such as Amazon, for hardware, software, networking, storage, and related technology we need to run the Mobile Application and
            Services. We maintain two types of logs: server logs and event logs. By using the Services, you authorize Henry Labs to transfer, store, and use your information in the United States and any other country where we operate.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s1" style={{ textIndent: '0pt', textAlign: 'justify' }}>What Information does Henry Labs Collect<span className="s2">?</span></p>
        <p className="s4" style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>
            Through your use of our Mobile Application and Services, we may collect PII, which is information that identifies an individual or relates to an identifiable individual. PII may include, but is not limited to, your first and last name,
            physical address, Social Security number, date of birth, and bank account information.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'left' }}>
            We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In
            particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <table style={{ borderCollapse: 'collapse' }} cellSpacing={0} cellpadding={`7.5px`}>
            <tbody>
                <tr>
                    <td align="center" style={{ width: '121pt' }}>
                        <p className="s5">Category</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s5">Examples</p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s5" >Collected</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">A. Identifiers.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7" >
                            A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver’s license number, passport number, or other similar
                            identifiers.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6" >B. Personal information categories listed in the California</p>
                        <p className="s6" >Customer Records statute (Cal. Civ. Code</p>
                        <p className="s6" >§ 1798.80(e)).</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">
                            A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education,
                            employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this
                            category may overlap with other categories.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">C. Protected classification</p>
                        <p className="s6">characteristics under California or federal law.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">
                            Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression,
                            pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7" >YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6" >D. Commercial information.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7" >
                            Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7" >NO</p>
                    </td>
                </tr>
                <tr >
                    <td style={{ width: '121pt' }}>
                        <p className="s6" >E. Biometric information.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7" >Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7" >NO</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">F. Internet or other similar network</p>
                        <p className="s6">activity.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">
                            Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">G. Geolocation data.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">Physical location or movements.</p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">H. Sensory data.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">Audio, electronic, visual, thermal, olfactory, or similar information.</p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">NO</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">I. Professional or employment-related</p>
                        <p className="s6">information.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">Current or past job history or performance evaluations.</p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">J. Non-public education information</p>
                        <p className="s6">(per the Family Educational Rights and Privacy Act (20</p>
                        <p className="s6">U.S.C. Section 1232g, 34 C.F.R. Part 99)).</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7" >
                            Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student
                            financial information, or student disciplinary records.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7" >NO</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '121pt' }}>
                        <p className="s6">K. Inferences drawn from other personal</p>
                        <p className="s6">information.</p>
                    </td>
                    <td style={{ width: '269pt' }}>
                        <p className="s7">
                            Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                        </p>
                    </td>
                    <td style={{ width: '53pt' }}>
                        <p className="s7">YES</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <ul id="l1">
            <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>Personal information does not include:</p>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Publicly available information from government records.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>De-identified or aggregated consumer information.</p></li>
            <li data-list-text="">
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Information excluded from the CCPA’s scope, like:</p>
                <ul id="l2">
                    <li data-list-text="o">
                        <p className="s8" style={{ paddingLeft: '83pt', textIndent: '-18pt', textAlign: 'left' }}>
                            health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
                        </p>
                    </li>
                    <li data-list-text="o">
                        <p className="s8" style={{ paddingLeft: '83pt', textIndent: '-18pt', textAlign: 'left' }}>
                            personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the
                            Driver’s Privacy Protection Act of 1994.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>We obtain the categories of personal information listed above from the following categories of sources:</p>
                    </li>
                </ul>
            </li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Directly from you (information you provide to us)</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Directly and indirectly from activity on our Mobile Application &amp; our Services.</p></li>
            <li data-list-text="">
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>From third-parties that interact with us in connection with the services we perform.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s9" style={{ paddingTop: '7pt', textIndent: '0pt', textAlign: 'left' }}>Information You Provide to Us:</p>
                <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>
                    We receive and store any information you knowingly provide to us. For example, through the registration process and/or through your account settings, we may collect Personal Information such as your name, email address, phone
                    number, and third-party account credentials (for example, your log-in credentials for a financial institution, financial service providers, social media, or other third party sites). If you provide your third-party account
                    credentials to us or otherwise sign in to the Services through a third party site or service, you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your
                    account with us if you authorize such transmissions, and that Third Party Account Information transmitted to our Services is covered by this Privacy Policy. Certain information may be required to register with us or to take
                    advantage of some of our features.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'justify' }}>
                    We may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses or email you about your use of
                    the Services. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make our communications with you more interesting and improve our services. If you do not want to receive
                    communications from us, please indicate your preference by sending us an email at <a href="mailto:support@clerkie.io" className="s3" target="_blank"><span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span></a><span style={{ color: '#000' }}>.</span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>
                    We may request additional PII that is necessary to provide one of our services (such as accessing your credit report), such as your physical address, Social Security number, mobile phone number, date of birth, bank account
                    information and numbers, employment and income information. Additionally, we may collect PII to verify your identity, for security-related purposes, or other administrative purposes. In order to provide Services to you, we may
                    on occasion supplement the PII you submit to us with information from third-party sources, such as consumer report information, including your credit score.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'justify' }}>
                    If you contact us, tell us your story, or otherwise provide us with information about yourself, we will maintain records of your correspondence. This may include any of your questions, comments, or suggestions.
                    <span style={{ color: '#000' }}>We may request access to your contacts in order to facilitate services including splitting bills, managing your budget with your partner, sharing accounts and transferring funds. </span>You may also
                    provide us with the email addresses of your contacts and include a personal message to your contacts if you choose to participate in our referral program, if available.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'justify' }}>
                    If you use our mobile application, we may ask for your permission to access certain information and features on your device, including your camera and photos, in order to facilitate the uploading of required documents or proof
                    of identity. You may also enable identity verification through your device’s Face ID or Touch ID feature, in which case you will provide your fingerprint or facial map data to your device. We do not collect Face ID or Touch ID
                    data.
                </p>
                <p className="s4" style={{ paddingTop: '10pt', textIndent: '0pt', textAlign: 'left' }}>
                    We may collect and store your location information if you enable your mobile device to send it to us. Certain features of the Services, including fraud detection and account locking features, may not be available if you do not
                    enable this collection.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s9" style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>Information Collected Automatically</p>
                <p className="s4" style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>We may automatically collect the following types of information as you use our Mobile Application and Services:</p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingTop: '3pt', paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Usage Information: <span className="s4">
                        We collect certain information automatically through your use of the Mobile Application, such as which of our web pages or mobile pages you access, the frequency of access, and what you click on while on our Mobile
                        Application.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Location Information: <span className="s4">We may collect information about your actual location which may be determined from your IP address and other sensors that may reveal information on nearby devices, Wi-Fi access points, and cell towers.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Device Information: <span className="s4">We may collect information about the device you are using such as: hardware model, operating system, application version number, browser, and IP addresses.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Mobile Device Information:
                    <span className="s4"> In addition to the Device Information listed above, when you access our Mobile Application or Services via a browser on your mobile device or through the use of our mobile application, we may also collect mobile network
                        information including phone number, the unique device identifier assigned to that device, mobile carrier, operating system, and other device attributes.
                    </span>
                </h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s11" style={{ textIndent: '0pt', textAlign: 'left' }}>Cookies and Other Tracking Technologies</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>A cookie is a small text file that is stored on a user’s computer for record-keeping purposes.</p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Session Cookies: <span className="s4">We use session cookies to make it easier for you to navigate our Mobile Application. The session ID cookie expires 24 hours after you sign in to our Mobile Application.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Persistent Cookies: <span className="s4">
                        We use persistent cookies that remain on your hard drive for an extended period of time. Persistent cookies also enable us to track and target the interests of our users to enhance the experience on our Mobile Application.
                        You can remove persistent cookies by following directions provided in your Internet browser’s “help” file.
                    </span>
                </h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'left' }}>
                    We may use a software technology called tracking pixels (a.k.a. web beacons or clear gifs) that help us better manage content on our Mobile Application by informing us what content is most effective. Tracking pixels are tiny
                    graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. In contrast to cookies, which are stored on a user’s computer hard drive, tracking pixels are embedded
                    invisibly on Web pages and are about the size of the period at the end of this sentence.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'left' }}>
                    We may also use other website or mobile tracking technologies, such as device fingerprinting, to identify you and keep track of your preferences, prevent fraudulent activity or improve Mobile Application security, assess the
                    performance of the Mobile Application, and deliver content to you based on your interests on our Mobile Application and third-party Mobile Applications.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                    Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or device, which may include your IP address, geolocation data, device identification, “cookie”
                    information, the type of browser and/or device you’re using to access our Services, and the page or feature you requested. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser
                    or device and tell us how and when pages and features in our Services are visited and by how many people. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of
                    cookies, but this may prevent you from taking advantage of some of our features.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                    Our advertising partners may also transmit cookies to your browser or device, when you click on ads that appear on the Services. Also, if you click on a link to a third party website or service, a
                </p>
                <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    third party may also transmit cookies to you. Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                    Please be aware that cookies placed by third parties may continue to track your activities online even after you have left our Services, and those third parties may not honor “Do Not Track” requests you have set using your
                    browser or device.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                    We may use this data to customize content for you that we think you might like, based on your usage patterns. We may also use it to improve the Services – for example, this data can tell us how often users use a particular
                    feature of the Services, and we can use that knowledge to make the Services interesting to as many users as possible.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s9" style={{ paddingTop: '7pt', textIndent: '0pt', textAlign: 'left' }}>Information Collected From Other Websites and Do Not Track Policy</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>
                    Through cookies we place on your browser or device, we may collect information about your online activity after you leave our Services. Just like any other usage information we collect, this information allows us to improve the
                    Services and customize your online experience, and otherwise as described in this Privacy Policy. Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and
                    services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at
                    this time, which means that we collect information about your online activity both while you are using the Services and after you leave our Services.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s11" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'justify' }}>Account and Transaction Information</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    We collect information about your use of our Services. For example, in processing a payment with you, such information would include your name, amount of payment, and your bank account information, including account numbers,
                    routing numbers, and similar information. Other information that we collect includes your transaction history, payment history, and account balance. We also collect tokens from online services and payment systems.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s1" style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>Will Henry Labs Share use and Share Any of the Personal Information it Receives<span className="s2">?</span></p>
                <p className="s4" style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>We use PII we collect in the following ways:</p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Process Transactions: <span className="s4">We use PII to provide our Services, including to consider application(s) for a financial products and process payments</span>
                </h1>
            </li>
            <li data-list-text="">
                <p className="s4" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    <i><b>Provide financial services: </b></i>We use PII to provide our Services, including considering your eligibility for specific financial services from 3<span className="s12">rd </span>parties (including debt relief organizations,
                    collections agencies and lenders)
                </p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Communicate: <span className="s4">
                        We use PII to communicate with you about our Mobile Application and Services. This may include payment reminders, information that you have requested, or notification of important changes to our Mobile Application and
                        Services. These communications may be delivered through push notifications if you have enabled that feature, or by phone call, text message, or email message.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Session Cookies: <span className="s4">We use session cookies to make it easier for you to navigate our Mobile Application. The session ID cookie expires 24 hours after you sign in to our Mobile Application.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingTop: '3pt', paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Marketing: <span className="s4">
                        We may occasionally send you information about products and services that we believe will be of interest to you. During any such communication, we will provide you with the option not to receive any future marketing
                        communications.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Business obligations: <span className="s4">To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Safety<span className="s4">: As necessary or appropriate to protect the rights, property or safety of us, our clients or others.</span></h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    As Required by Law: <span className="s4">To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Corporate Structure: <span className="s4">
                        To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                        proceeding, in which personal information held by us is among the assets transferred.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Specific Purposes: <span className="s4">
                        You may be asked to provide information for a specific purpose. For example, if you participate in our referral program, if available, you will be asked for information about your contacts. We will only use the information
                        that you provide for the purpose for which you provided it to us.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Customer Support: <span className="s4">We may use your PII to provide you support or other services you have ordered. For example, we may need your information in order to provide technical support or answer questions about your account.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Post Testimonials: <span className="s4">
                        If you choose to tell us your story or otherwise give a testimonial, we will review such information and may post such testimonial on our Mobile Application along with your name and any other PII that you choose to disclose
                        on your testimonial. By submitting a testimonial, you consent to posting any information and content, including video content, you provide in such testimonial on our Mobile Application. If you submit a testimonial, you may
                        revoke such consent at any time by emailing <a href="mailto:support@clerkie.io" target="_blank">support@clerkie.io</a></span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Site Maintenance and Improvement:  <span className="s4">
                        We analyze how our users interact with our Mobile Application and Services in order to maintain and improve our Mobile Application and Services. We also use information that we collect to diagnose any problems with our
                        Mobile Application or Services to improve the user experience.
                    </span>
                </h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                    We will not collect additional categories of personal information or use the personal information we collect for materially different, unrelated, or incompatible purposes without providing you notice.
                </p>
                <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>We do not rent or sell your Personal Information in personally identifiable form to anyone.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s13" style={{ paddingTop: '7pt', textIndent: '0pt', textAlign: 'left' }}>Sharing Personal Information</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    We may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both
                    keep that personal information confidential and not use it for any purpose except performing the contract.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
                <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left' }}>Category A: Identifiers.</p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>Category B: California Customer Records personal information categories.</p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>Category C: Protected classification characteristics under California or federal law.</p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>Category I: Professional or employment-related information.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>We disclose your personal information for a business purpose to the following categories of third parties:</p>
            </li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Our affiliates.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Service providers.</p></li>
            <li data-list-text="">
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>In the preceding twelve (12) months, we have not sold any personal information.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s4" style={{ textIndent: '0pt', textAlign: 'left' }}>We reserve the right to share information, including PII, with third parties, as described below:</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Service Providers: <span className="s4">We may share information with service providers that help us perform functions and process your transactions.</span></h1>
            </li>
            <li data-list-text="">
                <p className="s4" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    <i><b>Financial Service Providers: </b></i>as part of the debt plans and recommendations within the Mobile Application and Services, when you enroll, we may share information with 3<span className="s12">rd </span>party financial
                    service providers (such as credit counseling agencies, debt settlement companies, lenders and collections agencies) to process your request(s)
                </p>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Affiliates: <span className="s4">We may share information with our subsidiaries, affiliates, joint ventures, or other companies under common control with us.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Other Companies: <span className="s4">We may share information with other companies for purposes of marketing our products to you or for analyzing our business and transactions.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Corporate Structure:  <span className="s4">We may share information in connection with a merger, acquisition, consolidation, change of control, or sale of all or a portion of our assets or if we undergo bankruptcy or liquidation.</span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'justify' }}>
                    To Prevent Harm: <span className="s4">
                        We may share information if we believe it is necessary in order to detect, investigate, prevent, or take action against illegal activities, fraud, or situations involving potential threats to the rights, property, or
                        personal safety of any person.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    As Required by Law:  <span className="s4">
                        We will share information where we are legally required to do so, such as in response to court orders or legal process, to establish, protect, or exercise our legal rights, to defend against legal claims or demands, or to
                        comply with requirements of mandatory applicable law.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <h1 style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    With your Consent:  <span className="s4">
                        We may request your permission to share your PII for a specific purpose. We will notify you and request consent before you provide the PII or before the PII you have already provided is shared for such purpose.
                    </span>
                </h1>
            </li>
            <li data-list-text="">
                <p className="s14" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Information that’s been de-identified<span className="s2">: </span>
                    <span className="p">
                        We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our partners. We may also provide aggregate usage information to our partners (or allow partners
                        to collect that information from you), who may use such information to understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal online experience. However, we never
                        disclose aggregate usage or de-identified information to a partner (or allow a partner to collect such information) in a manner that would identify you as an individual person.
                    </span>
                </p>
            </li>
            <li data-list-text="">
                <p className="s14" style={{ paddingTop: '3pt', paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Advertisers: <span className="p">
                        We allow advertisers and/or merchant partners (“Advertisers”) to choose the demographic information of users who will see their advertisements and/or promotional offers and you agree that we may provide any of the
                        information we have collected from you in non-personally identifiable form to an Advertiser, in order for that Advertiser to select the appropriate audience for those advertisements and/or offers. For example, we might use
                        the fact you are located in San Francisco to show you ads or offers for San Francisco businesses, but we will not tell such businesses who you are. Or, we might allow Advertisers to display their ads to users with similar
                        usage patterns to yours, but we will not disclose usage information to Advertisers except in aggregate form, and not in a manner that would identify you personally. Note that if an advertiser asks us to show an ad to a
                        certain audience or audience segment and you respond to that ad, the advertiser may conclude that you fit the description of the audience they were trying to reach. We may deliver a file to you through the Services (known as
                        a “web beacon”) from an ad network. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted
                        advertisements to you when you visit other websites. Because your web browser must request these advertisements and web beacons from the ad network’s servers, these companies can view, edit, or set their own cookies, just as
                        if you had requested a web page from their site. You may be able to opt-out of web beacon tracking conducted by third parties through our Services by adjusting the Do Not Track settings on your browser; please note that we
                        don’t control whether or how these third parties comply with Do Not Track requests.
                    </span>
                </p>
            </li>
            <li data-list-text="">
                <p className="s14" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    3<span className="h2">rd </span>party Businesses: <span className="p">
                        In certain situations, businesses or third party websites we’re affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us). You can recognize
                        when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service.
                        One such service may include the ability for you to automatically transmit Third Party Account Information to your Services profile or to automatically transmit information in your Services profile to your third party
                        account. We have no control over the policies and practices of third party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website
                        or business, please review all such business’ or websites’ policies.
                    </span>
                </p>
            </li>
            <li data-list-text="">
                <p className="s14" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Agents: <span className="p">
                        We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Unless we tell you differently, our agents do not have any right to use
                        the Personal Information we share with them beyond what is necessary to assist us.
                    </span>
                </p>
            </li>
            <li data-list-text="">
                <p className="s14" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    User Profiles and Submissions:  <span className="p">
                        Certain user profile information, including your name, location, and any video or image content that such user has uploaded to the Services, may be displayed to other users to facilitate user interaction within the Services
                        or address your request for our services. Your account privacy settings may allow you to limit the other users who can see the Personal Information in your user profile and/or what information in your user profile is visible
                        to others. Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other users can view (on discussion boards,
                        in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your username may also be displayed to other users if and when you send messages or comments or upload images or videos
                        through the Services and other users can contact you through messages and comments. Additionally, if you sign into the Services through a third party social networking site or service, your list of “friends” from that site or service may be automatically imported to the Services, and such “friends,” if they are also registered users of the Services, may be able to access certain non-public information you have entered in
                        your Services user profile. Again, we do not control the policies and practices of any other third party site or service.
                    </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s1" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'left' }}>Is Personal Information about me secure<span className="s2">?</span></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>
                    Your account is protected by a password for your privacy and security. If you access your account via a third party site or service, you may have additional or different sign-on protections via that third party site or service.
                    You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanism. appropriately and limiting access to your computer or device and browser by
                    signing off after you have finished accessing your account.
                </p>
                <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'justify' }}>
                    We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and
                    other factors, may compromise the security of user information at any time.
                </p>
                <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>
                    The security of your PII is important to us. We maintain reasonable physical, technical, and administrative security measures to protect and limit access to your PII. No method of transmission over the Internet, or method of
                    electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your PII, we cannot guarantee its absolute security. If you have any questions about the security on our Mobile
                    Application, you can contact us at <a href="mailto:support@clerkie.io" className="s3" target="_blank">    <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span></a>.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s1" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'left' }}>What Personal Information can I access<span className="s2">?</span></p>
                <p style={{ paddingTop: '9pt', textIndent: '0pt', textAlign: 'left' }}>Through your account settings, you may access, and, in some cases, edit or delete the following information you’ve provided to us:</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <ul id="l3">
                    <li data-list-text=""><p style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>First / Last name</p></li>
                    <li data-list-text=""><p style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Password</p></li>
                    <li data-list-text=""><p style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Email address</p></li>
                    <li data-list-text=""><p style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Other user profile information</p></li>
                </ul>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingTop: '7pt', textIndent: '0pt', textAlign: 'left' }}>
                    The information you can view, update, and delete may change as the Services change. If you have any questions about viewing or updating information we have on file about you, please contact us at <a href="mailto:support@clerkie.io" className="s3" target="_blank">
                        <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span>
                    </a>.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s3" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Information to third parties for such third parties’ direct marketing purposes; in order
                    to submit such a request, please contact us at  <a href="mailto:support@clerkie.io" className="s3" target="_blank">
                        <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span>
                    </a><span style={{ color: '#000' }}>.</span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s1" style={{ textIndent: '0pt', textAlign: 'left' }}>What choices do I have<span className="s2">?</span></p>
                <p style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'justify' }}>
                    You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s3" style={{ textIndent: '0pt', textAlign: 'justify' }}>
                    You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. You may request deletion of your account by
                    contacting us at <a href="mailto:support@clerkie.io" className="s3" target="_blank">
                        <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span>
                    </a>
                    <span style={{ color: '#000' }}>
                        . Some information may remain in our records after your deletion of such information from your account. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it,
                        but not in a manner that would identify you personally.
                    </span>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s13" style={{ paddingTop: '7pt', textIndent: '0pt', textAlign: 'justify' }}>Your Rights and Choices</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s13" style={{ textIndent: '0pt', textAlign: 'left' }}>Access to Specific Information and Data Portability Rights</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will
                    disclose to you:
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            </li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>The categories of personal information we collected about you.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>The categories of sources for the personal information we collected about you.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Our business or commercial purpose for collecting or selling that personal information.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>The categories of third parties with whom we share that personal information.</p></li>
            <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>The specific pieces of personal information we collected about you (also called a data portability request).</p></li>
            <li data-list-text="">
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
                <ul id="l4">
                    <li data-list-text="o">
                        <p className="s8" style={{ paddingLeft: '83pt', textIndent: '-18pt', lineHeight: '94%', textAlign: 'left' }}>sales, identifying the personal information categories that each category of recipient purchased; and</p>
                    </li>
                    <li data-list-text="o">
                        <p className="s8" style={{ paddingLeft: '83pt', textIndent: '-18pt', lineHeight: '94%', textAlign: 'left' }}>
                            disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.
                        </p>
                    </li>
                </ul>
            </li>
        </ul >
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s13" style={{ textIndent: '0pt', textAlign: 'left' }}>Deletion Request Rights</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
            You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and
            direct our service providers to delete) your personal information from our records, unless an exception applies.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <ol id="l5">
            <li data-list-text={1.}>
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or
                    otherwise perform our contract with you.
                </p>
            </li>
            <li data-list-text={2.}>
                <p className="s8" style={{ paddingTop: '4pt', paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                </p>
            </li>
            <li data-list-text={3.}><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Debug products to identify and repair errors that impair existing intended functionality.</p></li>
            <li data-list-text={4.}>
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</p>
            </li>
            <li data-list-text={5.}>
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 <i>seq.</i>).</p>
            </li>
            <li data-list-text={6.}>
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                    Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible
                    or seriously impair the research’s achievement, if you previously provided informed consent.
                </p>
            </li>
            <li data-list-text={7.}><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</p></li>
            <li data-list-text={8.}><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '12pt', textAlign: 'left' }}>Comply with a legal obligation.</p></li>
            <li data-list-text={9.}>
                <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s13" style={{ textIndent: '0pt', textAlign: 'left' }}>Exercising Access, Data Portability, Deletion, and Opt-out Rights</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                    To exercise the access, portability, deletion, or opt-out rights described above, please submit a verifiable consumer request to us by either:
                </p>
                <ul id="l6">
                    <li data-list-text="">
                        <p style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>
                            <a href="mailto:support@clerkie.io" style={{ color: '#111', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '11pt' }} target="_blank">Email: </a>
                            <a href="mailto:support@clerkie.io" target="_blank">support@clerkie.io</a>
                        </p>
                    </li>
                    <li data-list-text="">
                        <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Mail: 619 7<span className="s17">th </span>St, San Francisco, CA 94103</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>You may also exercise your opt-out right to prevent the sale of your personal data here:</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><a href="https://app.clerkie.io/donotsell" className="s18">https://app.clerkie.io/donotsell</a></p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable
                            consumer request on behalf of your minor child.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>
                            You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:
                        </p>
                    </li>
                    <li data-list-text="">
                        <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>
                            Provide sufficient information that allows us to reasonably verify you are the person or an authorized representative of the person we collected personal information on.
                        </p>
                    </li>
                    <li data-list-text="">
                        <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>
                            We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable
                            consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s13" style={{ textIndent: '0pt', textAlign: 'left' }}>Response Timing and Format</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account
                            with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or  electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply
                            with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to
                            another entity without hindrance.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ textIndent: '0pt', textAlign: 'left' }}>
                            We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made
                            that decision and provide you with a cost estimate before completing your request.
                        </p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s13" style={{ textIndent: '0pt', textAlign: 'left' }}>Non-Discrimination</p>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className="s8" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                    </li>
                    <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Deny you goods or services.</p></li>
                    <li data-list-text="">
                        <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
                    </li>
                    <li data-list-text=""><p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left' }}>Provide you a different level or quality of goods or services.</p></li>
                    <li data-list-text="">
                        <p className="s8" style={{ paddingLeft: '47pt', textIndent: '-18pt', textAlign: 'left' }}>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
                    </li>
                </ul>
            </li>
        </ol>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s11" style={{ textIndent: '0pt', textAlign: 'left' }}>Correction</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
            If your PII changes, or if you no longer desire our service, you may correct or update your PII by contacting us at <a href="mailto:support@clerkie.io" className="s3" target="_blank">    <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span></a>. If you have an account with us
            and your account is in good standing, you may also correct or update your PII by logging into to your account on our Mobile Application and updating your information there.

        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s1" style={{ paddingTop: '8pt', textIndent: '0pt', textAlign: 'left' }}>What if I have questions about this policy?</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s3" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
            If you have any questions or concerns regarding our privacy policies, please send us a detailed message to <a href="mailto:support@clerkie.io" className="s3" target="_blank">    <span style={{ color: '#00f', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '11pt' }}>support@clerkie.io</span></a>
            <span style={{ color: '#000' }}>, and we will try to resolve your concerns.</span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s11" style={{ textIndent: '0pt', textAlign: 'left' }}>Applicable Law</p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>
            This is a United States-based Mobile Application and Service that is subject to United States law. This Mobile Application and Service is controlled, operated, and administered by Henry Labs, Inc from its offices within the United
            States of America. This Policy is provided in accordance with and subject to applicable U.S. law. If you are accessing this Mobile Application from a location outside the United States, you hereby agree that your use of this Mobile
            Application and our collection and use of your PII is subject to this Policy.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
        <p className="s19" style={{ textIndent: '0pt', textAlign: 'left' }}>Contact Us</p>
        <p className="s4" style={{ paddingTop: '4pt', textIndent: '0pt', textAlign: 'left' }}>Henry Labs, Inc 619 7<span className="s12">th </span>St</p>
        <p className="s4" style={{ textIndent: '0pt', textAlign: 'left' }}>San Francisco, CA 94103</p>
        <style jsx="true">{` 
.container {
    margin: 75px;
    padding: 0px;
    text-indent: 0;
}

.s1 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 11pt;
}

.p,
p {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
    margin: 0pt;
}

.s2 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

.a,
a {
    color: #00F;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 11pt;
}

.s3 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.s4 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.s5 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

.s6 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

.s7 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.s8 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.s9 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: underline;
    font-size: 11pt;
}

.s10 {
    color: #00F;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

h1 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

.s11 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 11pt;
}

.s12 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
}

.s13 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 11pt;
}

.s14 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

.h2 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}

.s15 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

.s17 {
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
}

.s18 {
    color: #00F;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}

.s19 {
    color: #252525;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
}

#l1>li>*:first-child:before {
    content: "• ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    padding-right: 12px;
}

#l2 {
    padding-left: 0pt;
}

#l2>li>*:first-child:before {
    content: "o ";
    color: #111;
    font-family: "Courier New", monospace;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

#l3 {
    padding-left: 0pt;
}

#l3>li>*:first-child:before {
    content: "• ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
    padding-right: 12px;
}

#l4 {
    padding-left: 0pt;
}

#l4>li>*:first-child:before {
    content: "o ";
    color: #111;
    font-family: "Courier New", monospace;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

li {
    display: block;
}

#l5 {
    padding-left: 0pt;
    counter-reset: e1 1;
}

#l5>li>*:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal)". ";
    color: #111;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}

#l5>li:first-child>*:first-child:before {
    counter-increment: e1 0;
}

#l6 {
    padding-left: 0pt;
}

#l6>li>*:first-child:before {
    content: "• ";
    color: #111;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
    padding-right: 12px;
}

table,
tbody {
    vertical-align: top;
    overflow: visible;
}

table {
    width: 100%;
}

td {
    border: 1px solid black;
    text-align: center;
}

@media (max-width: 640px) { 

    .container {
        margin: 15px;
        padding: 0px;
        text-indent: 0;
    }
}

        `}</style>
    </div >
}